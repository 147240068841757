import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, InjectionToken, Injector, Input, NgZone, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { KurzIconType } from '../../../custom/custom-configuration-modules/custom-icon-types-mapping.module';
import { ColorThemableComponent } from '../shared/color-theme/theme.enum';

export interface CustomPanelPartData<T = any> {
  instance?: PanelComponent;
  injectedData?: T;
}

export interface CustomPanelPartObject<T = any> {
  titleComponent?: any;
  footerComponent?: any;
  data?: CustomPanelPartData<T>;
  __injector?: Injector;
}


// eslint-disable-next-line
export const CUSTOM_TITLE_CMP_DATA_TOKEN = new InjectionToken<CustomPanelPartData>('CustomTitleComponentObject_Token');

type NumberBetween1And5 = 1 | 2 | 3 | 4 | 5;

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
  exportAs: 'app-panel',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PanelComponent<T = any> implements ColorThemableComponent, AfterViewInit, OnDestroy {

  private _customPanelPartObject: CustomPanelPartObject<T>;
  private _toggleButtonPosition: 'left' | 'right' | 'hidden' = 'right';
  private _resizeObserver: ResizeObserver;
  private _mutationObserver: MutationObserver;
  private _hostElement: HTMLElement;

  private _headerOrder: NumberBetween1And5 = 2;

  @ViewChild('panelContentElement')
  panelContentEl: ElementRef;

  @HostBinding('class')
  get panelOrderClazz(): string[] {
    const arr = [
      'panel-order-' + this.headerOrder,
      'panel-toggle-button-' + this.toggleButtonPosition
    ];
    return arr;
  }

  collapseIconType = KurzIconType.CARET_DOWN;

  @Input()
  collapsed = false;

  @Input()
  collapsable: boolean = true;

  get headerOrder(): NumberBetween1And5 {
    return this._headerOrder;
  }

  @Input()
  set headerOrder(value: NumberBetween1And5) {
    this._headerOrder = typeof value === 'string' ? (parseInt(value, 10) as NumberBetween1And5) : value;
  }

  @Input()
  title: string;

  @Input()
  set customPanelPartObject(value: CustomPanelPartObject<T>) {
    if (value && !value.__injector) {
      value.__injector = Injector.create({
        providers: [
          {provide: CUSTOM_TITLE_CMP_DATA_TOKEN, useValue: {instance: this, injectedData: value.data?.injectedData}}
        ],
        parent: this.injector,
        name: 'app-panel-custom-title'
      });
    }

    this._customPanelPartObject = value;
  }

  get customPanelPartObject(): CustomPanelPartObject<T> {
    return this._customPanelPartObject;
  }

  get templateContext(): any {
    return {
      $implicit: this.title
    };
  }

  @Input()
  set toggleButtonPosition(value: 'left' | 'right' | 'hidden') {
    this._toggleButtonPosition = value;
  }

  get toggleButtonPosition(): 'left' | 'right' | 'hidden' {
    return this._toggleButtonPosition;
  }

  constructor(private readonly elementRef: ElementRef, private readonly injector: Injector, private ngZone: NgZone) { }

  ngAfterViewInit() {
    this.updateContentHeight();
    this._hostElement = this.elementRef?.nativeElement as HTMLElement;
    if (this._hostElement) {
      this._resizeObserver = new ResizeObserver(_ => this.updateContentHeight());
      this._resizeObserver.observe(this._hostElement);
      this._mutationObserver = new MutationObserver(_ => this.updateContentHeight());
      this._mutationObserver.observe(this._hostElement, {
        subtree: true,
        childList: true,
        attributes: true,
        attributeFilter: ['class']
      });
      setTimeout(() => {
        this._hostElement.style.setProperty('--content-transition-duration', '250ms');
      }, 0);
    }
  }

  ngOnDestroy(): void {
    this._resizeObserver?.disconnect();
    this._mutationObserver?.disconnect();
  }

  clickOnHeader(event: MouseEvent) {
    this.toggle();
    if (event instanceof KeyboardEvent && event.key === ' ') {
      event.preventDefault();
    }
  }

  toggle(fromExternal?: boolean) {
    if (this.collapsable) {
      this.updateContentHeight();
      this.collapsed = !this.collapsed;
      if (fromExternal) {
        const cdr = this.injector.get(ChangeDetectorRef);
        cdr?.detectChanges();
      }
    }
    this.signalingActivity();
  }

  private updateContentHeight() {
    const host = this.panelContentEl?.nativeElement as HTMLElement;
    const height = host?.getBoundingClientRect()?.height ?? 1000;
    (this.elementRef?.nativeElement as HTMLElement)?.style?.setProperty('--content-height', height + 'px');
  }

  /**
   * signals activity to potential parent panels and trigger their mutation observer through rabbit class changes
   */
  private signalingActivity() {

    const host = this.panelContentEl?.nativeElement as HTMLElement;

    if (!host) {
      return void 0;
    }
    let counter = 0;

    const _signalingFn = (action: 'add' | 'remove' | 'both') => {
      if (action === 'remove' || action === 'both') {
        host.classList.remove('signal-flare-' + counter);
      }
      counter++;
      if (action === 'add' || action === 'both') {
        host.classList.add('signal-flare-' + counter);
      }

    };

    this.ngZone.runOutsideAngular(() => {

      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      const num = setInterval(() => _signalingFn('both'), 50);

      setTimeout(() => {
        clearInterval(num);
        _signalingFn('remove');
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers, no-magic-numbers
      }, 301);

    });

  }

}
