
<div
  [ngClass]="[
    'panel-header',
    (collapsable ? 'panel-header-collapsable' : ''),
    'panel-header-' + (collapsed ? 'collapsed' : 'expanded')
  ]"
  [tabindex]="(collapsable ? '0' : '-1')"
  (activateElement)="clickOnHeader($event)"
>

  <ng-template #normalTitle>
    @switch (headerOrder) {
      @case (1) {
        <h1 class="panel-header-title panel-header-title-h1">{{title}}</h1>
      }
      @case (2) {
        <h2 class="panel-header-title panel-header-title-h2">{{title}}</h2>
      }
      @case (3) {
        <h3 class="panel-header-title panel-header-title-h3">{{title}}</h3>
      }
      @case (4) {
        <h4 class="panel-header-title panel-header-title-h4">{{title}}</h4>
      }
      @case (5) {
        <h5 class="panel-header-title panel-header-title-h5">{{title}}</h5>
      }
      @default {
        <div></div>
      }
    }

  </ng-template>

  <aside *ngIf="toggleButtonPosition === 'left'" [ngClass]="['panel-aside']">
    <app-icon
      [ngClass]="['panel-toggle-button', 'panel-toggle-button-' + (collapsed ? 'collapsed' : 'expanded')]"
      [iconType]="collapseIconType"
    ></app-icon>
  </aside>

  <ng-container *ngIf="customPanelPartObject?.titleComponent; else normalTitle">
    <ng-container
      [ngComponentOutlet]="customPanelPartObject.titleComponent"
      [ngComponentOutletInjector]="customPanelPartObject.__injector"
    ></ng-container>
  </ng-container>

  <aside *ngIf="toggleButtonPosition === 'right'" [ngClass]="['panel-aside']">
    <app-icon
      [ngClass]="['panel-toggle-button', 'panel-toggle-button-' + (collapsed ? 'collapsed' : 'expanded')]"
      [iconType]="collapseIconType"
    ></app-icon>
  </aside>

</div>

<section [ngClass]="['panel-section', 'panel-section-' + (collapsed ? 'collapsed' : 'expanded')]">
  <div #panelContentElement class="panel-content-container">
    <ng-content></ng-content>
  </div>
</section>

<footer *ngIf="customPanelPartObject?.footerComponent">
  <ng-container
    [ngComponentOutlet]="customPanelPartObject.footerComponent"
    [ngComponentOutletInjector]="customPanelPartObject.__injector"
  ></ng-container>
</footer>
